import React, { useState } from "react";
import imageOverlay from "../img/earth.jpg";
import Swal from "sweetalert2";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    const errors = {};
    if (!formData.name || formData.name.length < 4) {
      errors.name = "Napíšte aspoň 4 znaky";
    }
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Napíšte platný email";
    }
    if (!formData.subject || formData.subject.length < 8) {
      errors.subject = "Napíšte aspoň 8 znakov";
    }
    if (!formData.message) {
      errors.message = "Napíšte niečo";
    }
    return errors;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      fetch("https://formspree.io/f/xvgpzgen", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }).then((response) => {
        if (response.ok) {
          Swal.fire({
            title: "Úspech!",
            text: "Vaša správa bola odoslaná.",
            icon: "success",
            confirmButtonText: "OK",
          });
          setFormData({
            name: "",
            email: "",
            subject: "",
            message: "",
          });
        }
      });
    }
  };

  return (
    <section
      className="paralax-mf footer-paralax bg-image sect-mt4 route"
      style={{ backgroundImage: "url(" + imageOverlay + ")" }}
    >
      <div className="overlay-mf"></div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="contact-mf">
              <div id="contact" className="box-shadow-full">
                <div className="row">
                  <div className="col-md-6">
                    <div className="title-box-2">
                      <h5 className="title-left">Pošlite mi správu</h5>
                    </div>
                    <div>
                      <form onSubmit={handleSubmit} className="contactForm">
                        <div id="sendmessage">
                          Vaša správa bola odoslaná. Vďaka!
                        </div>
                        <div id="errormessage"></div>
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <div className="form-group">
                              <input
                                type="text"
                                name="name"
                                className={`form-control ${
                                  errors.name ? "is-invalid" : ""
                                }`}
                                id="name"
                                placeholder="Vaše meno"
                                value={formData.name}
                                onChange={handleChange}
                              />
                              {errors.name && (
                                <div className="invalid-feedback">
                                  {errors.name}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="form-group">
                              <input
                                type="email"
                                className={`form-control ${
                                  errors.email ? "is-invalid" : ""
                                }`}
                                name="email"
                                id="email"
                                placeholder="Váš email"
                                value={formData.email}
                                onChange={handleChange}
                              />
                              {errors.email && (
                                <div className="invalid-feedback">
                                  {errors.email}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="form-group">
                              <input
                                type="text"
                                className={`form-control ${
                                  errors.subject ? "is-invalid" : ""
                                }`}
                                name="subject"
                                id="subject"
                                placeholder="Predmet"
                                value={formData.subject}
                                onChange={handleChange}
                              />
                              {errors.subject && (
                                <div className="invalid-feedback">
                                  {errors.subject}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="form-group">
                              <textarea
                                className={`form-control ${
                                  errors.message ? "is-invalid" : ""
                                }`}
                                name="message"
                                rows="5"
                                placeholder="Vaša správa"
                                value={formData.message}
                                onChange={handleChange}
                              ></textarea>
                              {errors.message && (
                                <div className="invalid-feedback">
                                  {errors.message}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <button
                              type="submit"
                              className="button button-a button-big button-rouded"
                            >
                              Odoslať správu
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="title-box-2 pt-4 pt-md-0">
                      <h5 className="title-left">Spojte sa s nami</h5>
                    </div>
                    <div className="more-info">
                      <p className="lead">
                        Ak sa chcete s nami spojiť, dohodnúť si stretnutie
                        ohľadom projektu alebo niečo konzulovať, neváhajte nás
                        kontaktovať.
                        <br />
                        Jednoducho vyplňte formulár a kliknite na tlačidlo
                        odoslať.
                      </p>
                      <ul className="list-ico">
                        <li>
                          <span className="ion-ios-location"></span>{" "}
                          Duklianskych Hrdinov 1236, 09301 Vranov nad Topľou
                        </li>
                        <li>
                          <span className="ion-email"></span>{" "}
                          matusblacha@websheet.eu
                        </li>
                      </ul>
                    </div>
                    <div className="socials">
                      <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/majkldzeksn2/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="ico-circle">
                              <i className="ion-social-facebook"></i>
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/matus.blacha/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="ico-circle">
                              <i className="ion-social-instagram"></i>
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/in/mat%C3%BA%C5%A1-b%C4%BEacha-2a240b215/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="ico-circle">
                              <i className="ion-social-linkedin"></i>
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="copyright-box"></div>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default Contact;
