/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import logo2 from "../img/memo1.png";

//import stock
import inzagintro from "../img/inzagintro.png";
import inzag1 from "../img/inzag1.png";
import inzag2 from "../img/inzag2.png";
import inzag3 from "../img/inzag3.jpg";
import inzag4 from "../img/inzag4.jpg";
import inzag5 from "../img/inzag5.jpg";
import livesheetintro from "../img/livesheetintro.png";
import livesheet1 from "../img/livesheet1.png";
import livesheet2 from "../img/livesheet2.png";
import livesheet3 from "../img/livesheet3.png";
import livesheet4 from "../img/livesheet4.png";
import letiskointro from "../img/letiskointro.png";
import letisko1 from "../img/letisko1.png";
import letisko2 from "../img/letisko2.png";
import letisko3 from "../img/letisko3.png";
import letisko4 from "../img/letisko4.png";
import letisko5 from "../img/letisko5.png";

class Portfolio extends React.Component {
  render() {
    return (
      <section id="work" className="portfolio-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h3 className="title-a">Portfólio</h3>
                <a href={logo2}></a>
                <p className="subtitle-a">Pozrite si našu prácu.</p>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="work-box">
                <a href={inzagintro} data-lightbox="gallery-vmarine">
                  <div className="work-img">
                    <img src={inzagintro} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">Inžinierska agentúra</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            HTML5 CSS ReactJS NodeJS SmoothScrolling
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href={inzag1}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={inzag2}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={inzag3}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={inzag4}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={inzag5}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-box">
                <a href={livesheetintro} data-lightbox="gallery-aguadeluz">
                  <div className="work-img">
                    <img src={livesheetintro} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">LiveSheet</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            HTML5 CSS ReactJS NodeJS MongoDB
                          </span>{" "}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href={livesheet1}
                  data-lightbox="gallery-aguadeluz"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={livesheet2}
                  data-lightbox="gallery-aguadeluz"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={livesheet3}
                  data-lightbox="gallery-aguadeluz"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={livesheet4}
                  data-lightbox="gallery-aguadeluz"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-box">
                <a href={letiskointro} data-lightbox="gallery-todo">
                  <div className="work-img">
                    <img src={letiskointro} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">
                          Online Letisko v spolupráci s iiTeam
                        </h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            HTML5 CSS Flask PostgreSQL ReactJS
                          </span>
                          {/*} / <span className="w-date">18 Sep. 2018</span>*/}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href={letisko1}
                  data-lightbox="gallery-todo"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={letisko2}
                  data-lightbox="gallery-todo"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={letisko3}
                  data-lightbox="gallery-todo"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={letisko4}
                  data-lightbox="gallery-todo"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={letisko5}
                  data-lightbox="gallery-todo"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
