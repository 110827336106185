import React from 'react';

const Footer = () => {
  return (
    <footer className="footer-mf">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="copyright-box">
              <p className="copyright">&copy; 2024. All rights reserved.</p>
              <div className="credits">
                Created by <a href="https://websheet.eu">Matúš Bľacha</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
